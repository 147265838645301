import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import HeroBanner from 'components/HeroBanner';
import ResumePage from './resume';
// import Services from 'components/Services'; // no services
// import Testimonials from 'components/Testimonials'; // no testimonials

const IndexPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="About Me" />
      <HeroBanner />
      {/* <Services /> */}
      <hr />
      <ResumePage />
      {/* <Testimonials /> */}
    </Layout>
  );
};

export default IndexPage;
